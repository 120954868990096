<template>
  <VDialog
    v-model="visibility"
    @click:outside="showConfirmDialog"
    :persistent="changed"
    content-class="cross__dialog"
    max-width="600px"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :loading="loading">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <VRow v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <div class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("btn.update")} ${$t("users.user")}`
        }}</div>
        </VRow>
        <div v-if="$vuetify.breakpoint.smAndDown" class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("btn.update")} ${$t("users.user")}`
        }}</div>
        <VSlideXReverseTransition>
          <VMenu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="cursor-pointer"
                icon
                v-on="on"
                v-bind="attrs"
                small
              >
                <VIcon color="grey lighten-1" dense>
                  mdi-dots-vertical
                </VIcon>
              </VBtn>
            </template>
            <VList class="pa-0" dense>
              <VListItem
                v-if="userItem.is_login"
                @click="unloginUser"
                link
                class="pa-0"
              >
                <VRow no-gutters>
                  <VBtn text>
                    {{ $t("users.disconnect_user") }}
                  </VBtn>
                </VRow>
              </VListItem>
              <VListItem class="pa-0" v-if="canBlock && user.id_state == '210' " @click="blockUser(true)" link>
                <VRow no-gutters>
                  <VBtn color="error" text>{{ $t("users.block_user") }}</VBtn>
                </VRow>
              </VListItem>
              <VListItem class="pa-0" v-if="canBlock && user.id_state == '250' " @click="blockUser(false)" link>
                <VRow no-gutters>
                  <VBtn color="success" text>{{ $t("users.unblock_user") }}</VBtn>
                </VRow>
              </VListItem>
            </VList>
          </VMenu>
        </VSlideXReverseTransition>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <VRow :class="$vuetify.breakpoint.smAndDown ? 'align-center mt-3 justify-center' : 'align-start mt-3 justify-start'">
            <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'flex'">

              <VRow v-if="!photoPreview && !userItem.path_img && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="!photoPreview && !userItem.path_img && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
                  </label>
                </VCol>
              </VRow>

              <VRow v-if="userItem.path_img && !photoPreview && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img :src="userItem.path_img" alt="" width="80" class="img-preview" />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="userItem.path_img && !photoPreview && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img :src="userItem.path_img" alt="" width="80" class="img-preview" />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
                  </label>
                </VCol>
              </VRow>
              <VFileInput
                id="customer_photo"
                class="d-none"
                v-model="user.image"
                accept="image/*"
                @change="makePreview"
              />
              <VRow v-if="photoPreview && !$vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="3">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <VBtn @click="onChangeImage" :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`">
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
              <VRow v-if="photoPreview && $vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="12">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <VBtn @click="onChangeImage" :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`">
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
            <VRow class="align-start mt-3 justify-start">
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.name')"
                  dense
                  color="green"
                  outlined
                  v-model="user.name"
                  :error-messages="nameErrors"
                  :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                  required
                  v-on:keypress="validationForName($event)"
                  @paste="validationForName($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.surname')"
                  dense
                  outlined
                  color="green"
                  v-model="user.surname"
                  :error-messages="surnameErrors"
                  :hint="onEngText ? '' :  $t('form.hint.SurnameNoEngText')"
                  required
                  v-on:keypress="validationForSurname($event)"
                  @paste="validationForSurname($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  class="inputPhone"
                  :label="$t('form.phone_number')"
                  dense
                  color="green"
                  prefix="+38"
                  :maxLength="20"
                  outlined
                  v-model="user.phone"
                  :error-messages="phoneErrors"
                  required
                  v-on:keypress="validationForPhoneNumber($event)"
                  @paste="validationForPhoneNumber($event)"
                >
                </VTextField>
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="accessRole">
                <v-autocomplete
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="$t('table.noData')"
                  dense
                  v-model="user.deport_uuid"
                  :error-messages="uuidErrors"
                  hide-details="auto"
                  :disabled="!permissions.can_delete_manager"
                  color="success"
                  item-color="success"
                  v-on:keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
            </VRow>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              @click="onClose"
              block
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onUpdate"
              :disabled="!changed"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />

    <ConfirmDialog
      v-if="canBlock"
      :visible="blockDialog"
      @close="blockDialog = false"
      @onConfirm="onBlock"
      @onCancel="blockDialog = false"
      :typeAlert="blocked ? 'error' : 'success'"
    >
    <template v-slot:header>
      {{blockTitle}}
    </template>
    <template v-slot:description>
     {{blockDesc}}
    </template>
    </ConfirmDialog>
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import phoneMask from "../../validators/phoneMask";
import {
  validationForName,
  validationForSurname,
  validationForPhoneNumber,
  validationForWarehouses
} from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import fileSizeValidation from "@/validators/fileSizeAvatar";

export default {
  name: "EditUserDialog",
  mixins: [language, notifications, validationMixin, user],
  validations: {
    user: {
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      },
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator},
      phone: { required, phoneMask },
      image: { fileSizeValidation },
    }
  },
  data: () => ({
    photoPreview: null,
    onEngText: true,
    inputUser: {},
    user: {
      uuid: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      deport_uuid: "",
      image: null
    },
    visibleConfirm: false,
    depots: [],
    accessRole: false,
    loading: false,
    changed: false,
    blocked: false,
    blockTitle: null,
    blockDesc: null,
    blockDialog: false,
  }),
  components: {
    ConfirmLeftDialog,
    ConfirmDialog,
  },
  async mounted() {
    // orderDialog("role", this.role);
    this.updateRoleInfo();
    if(this.permissions.can_edit_client) {
      try {
        this.depots = await depotService.getDepotsList();
      } catch (e) {
        console.log(e)
      }
    }
    this.user = {
      uuid: this.userItem.uuid,
      name: this.userItem.name,
      surname: this.userItem.surname,
      deport_uuid: this.userItem?.uuid_deport,
      email: this.userItem.email,
      id_state: this.userItem.id_state,
      is_login: this.userItem.is_login,
      phone: this.userItem.phone.replace("+38", ""),
    };
    this.inputUser = {
      ...this.user
    };
  },
  methods: {
    validationForWarehouses,
    validationForPhoneNumber,
    validationForSurname,
    validationForName,
    unloginUser(){
      this.user.is_login = false;
      this.onUpdate()
    },
    onResetImage(){
      this.photoPreview = false
    },
    onChangeImage() {
      this.user.image = null;
      this.inputUser.image = null;
      this.photoPreview = null;
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    makePreview() {
      if(this.$v.user.image.fileSizeValidation){
      let photoPreview = null;
      const { image } = this.user;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    } else {
        this.user.image = null;
        this.inputUser.image = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
    }
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    blockUser(check) {

      this.blocked = check
      if (this.blocked) {
        this.blockTitle = this.$t('confirmDialog.block_user.title')
        this.blockDesc = this.$t('confirmDialog.block_user.desc')
      }
      else {
        this.blockTitle = this.$t('confirmDialog.unblock_user.title')
        this.blockDesc = this.$t('confirmDialog.unblock_user.desc')
      }
      this.blockDialog = true
    },
    updateRoleInfo() {
      // orderDialog(123123);
      // orderDialog(this.permissions.can_create_user_deport);
      if (this.role === "employee" || this.role === "client" || this.role === "manager") {
        if (this.permissions.can_edit_client) {
          this.accessRole = true;
          // this.validations.user.deport_uuid.required = true;
        }
      }
    },
    onBlock() {
      if (this.blocked) {
        this.user.id_state = "250"
      }
      else this.user.id_state = "210"

      this.onUpdate()
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.user.uuid);
          if (this.user.image) {
            formData.append("image", this.user.image);
          }

          const changedData = this.$getChangedData(this.user, this.inputUser);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          await usersService.updateUser(formData);
          this.setSuccessNotification(this.$t("users.user_update_success"));
          this.loading = false;
          EventBus.$emit("updated-user");
          this.visibility = false;
          this.$emit("resetFilter", true);
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    }
  },
  props: {
    visible: {
      required: true
    },
    userItem: {
      required: true
    },
    role: {
      required: true,
      type: String
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.user, this.inputUser);
      }
    },
    role: "updateRoleInfo"
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.user.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.deport_uuid.required && errors.push(this.$t("form.errors.StorageRequired"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t("form.errors.PasswordRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email && errors.push(this.$t("form.errors.PasswordMustBeLike"));
      return errors;
    }
  }
};
</script>

<style scoped>
.img-preview {
  border-radius: 15%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
